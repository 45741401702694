import React, { CSSProperties, useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import cx from 'classnames'
import Container from '../container'
import * as s from './Header.module.scss'
import { Menu11Icon, Cancel01Icon } from 'hugeicons-react'

import Typist from 'react-typist'

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      script: allFile(filter: { base: { eq: "Hlaupari.pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      synopsis: allFile(filter: { base: { eq: "synopsis.pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      vendipunktar: allFile(filter: { base: { eq: "vendipunktar.pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      refs: allFile(filter: { base: { eq: "refs.pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      logline: allFile(filter: { base: { eq: "logline.pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
      plan: allFile(filter: { base: { eq: "plan.pdf" } }) {
        edges {
          node {
            publicURL
          }
        }
      }
    }
  `)
  console.log(data)
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleEscape = (e) => {
    if (e.key === 'Escape') {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEscape)
      document.body.style.overflow = 'hidden'
    }
    return () => document.removeEventListener('keydown', handleEscape)
  }, [isOpen, handleEscape])

  return (
    <>
      <header className={cx(s.header, { [s.open]: isOpen })}>
        <Container className={s.header__inner}>
          {/* <Link to="/" className={s.link}>
          <h1>Hlaupari</h1>
        </Link> */}
          <Typist
            startDelay={800}
            avgTypingDelay={100}
            // cursor={{ hideWhenDone: true, hideWhenDoneDelay: 5000 }}
            className={s.typist}
          >
            <h1>Hlaupari</h1>
          </Typist>

          <button className={s.hamburger} onClick={toggleMenu}>
            {/* <FaBars color={isOpen ? '#000' : '#fff'} onClick={toggleMenu} size={30} /> */}
            <span>{isOpen ? 'Loka' : 'Valmynd'}</span>
            {isOpen ? (
              <Cancel01Icon onClick={() => setIsOpen(false)} size={30} color="#000" />
            ) : (
              <Menu11Icon onClick={() => setIsOpen(true)} size={30} color="#fff" />
            )}
            {/* <Menu11Icon color={isOpen ? '#000' : '#fff'} size={30} /> */}
          </button>
        </Container>
      </header>
      <div className={cx(s.menu, { [s.active]: isOpen })}>
        <div className={cx(s.items, { [s.visible]: isOpen })}>
          <a
            className={s.item}
            href={data.script.edges[0].node.publicURL}
            style={{ '--i': 0 } as CSSProperties}
          >
            {/* <sup>01</sup> */}
            <span>Handrit →</span>
          </a>
          <a
            className={s.item}
            href={data.synopsis.edges[0].node.publicURL}
            style={{ '--i': 1 } as CSSProperties}
          >
            {/* <sup>01</sup> */}
            <span>Synopsis →</span>
          </a>
          <a
            className={s.item}
            href={data.refs.edges[0].node.publicURL}
            style={{ '--i': 2 } as CSSProperties}
          >
            {/* <sup>03</sup> */}
            <span>Reffar →</span>
          </a>
          <Link className={s.item} to="/lokasena" style={{ '--i': 3 } as CSSProperties}>
            {/* <sup>02</sup> */}
            <span>Lokasena →</span>
          </Link>
          <a
            className={s.item}
            href={data.vendipunktar.edges[0].node.publicURL}
            style={{ '--i': 4 } as CSSProperties}
          >
            {/* <sup>03</sup> */}
            <span>Vendipunktar →</span>
          </a>
          <Link to="/logline" className={s.item} style={{ '--i': 5 } as CSSProperties}>
            {/* <sup>04</sup> */}
            <span>Logline →</span>
          </Link>
          <a
            className={s.item}
            href={data.plan.edges[0].node.publicURL}
            style={{ '--i': 6 } as CSSProperties}
          >
            {/* <sup>05</sup> */}
            <span>Plan →</span>
          </a>
        </div>
      </div>
    </>
  )
}
export default Header

import React from 'react'
import Helmet from 'react-helmet'
// import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
// import cx from 'classnames'
// import Typist from 'react-typist'
import 'react-typist/dist/Typist.css'
import Layout from '../components/Layout'
import Header from '../components/header'
// import heroImage from '../assets/images/hlaupari-hero.jpeg'
// import PDF from '../components/icons/PDF'
import * as s from './index.module.scss'
// import Container from '../components/container'

export default () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     script: allFile(filter: { base: { eq: "hlaupari.pdf" } }) {
  //       edges {
  //         node {
  //           publicURL
  //         }
  //       }
  //     }
  //     refs: allFile(filter: { base: { in: ["refs.pdf"] } }) {
  //       edges {
  //         node {
  //           publicURL
  //         }
  //       }
  //     }
  //     logline: allFile(filter: { base: { eq: "logline.pdf" } }) {
  //       edges {
  //         node {
  //           publicURL
  //         }
  //       }
  //     }
  //     plan: allFile(filter: { base: { eq: "plan.pdf" } }) {
  //       edges {
  //         node {
  //           publicURL
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Layout>
      <Header />
      <Helmet title="Forsíða" />
      <div className={s.hero}>
        {/* <Typist
          startDelay={800}
          avgTypingDelay={100}
          // cursor={{ hideWhenDone: true, hideWhenDoneDelay: 5000 }}
          className={s.typist}
        >
          <h1>Hlaupari</h1>
        </Typist> */}
        {/* <Container> */}
        {/* <div className={cx(s.items, { [s.visible]: visible })}>
            <a className={s.item} href={data.script.edges[0].node.publicURL}>
              <sup>01</sup>
              <span>Handrit →</span>
            </a>
            <Link className={s.item} to="/lokasena">
              <sup>02</sup>
              <span>Lokasena →</span>
            </Link>
            <a className={s.item} href={data.refs.edges[0].node.publicURL}>
              <sup>03</sup>
              <span>Reffar →</span>
            </a>
            <a className={s.item} href={data.logline.edges[0].node.publicURL}>
              <sup>04</sup>
              <span>Logline →</span>
            </a>
            <a className={s.item} href={data.plan.edges[0].node.publicURL}>
              <sup>05</sup>
              <span>Plan →</span>
            </a>
          </div> */}
        {/* </Container> */}
        <StaticImage
          layout="constrained"
          src="../assets/images/hlaupari-hero.jpeg"
          alt="Hlaupari"
          fetchPriority="high"
          placeholder="dominantColor"
          className={s.hero__img}
          objectFit="contain"
        />
      </div>
    </Layout>
  )
}
